import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Transfer from '../views/Transfer.vue';
import TwoYeartoFourYear from "../views/TwoYeartoFourYear.vue";
import GeneratedPlans from "../views/GeneratedPlans.vue";
import PlanDetails from '../views/PlanDetails.vue' // Import the component


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: '/transfer',
      name: 'transfer',
      component: Transfer
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import("../views/AboutView.vue"),
    },
    {
      path: '/TwoYeartoFourYear',
      name: 'TwoYeartoFourYear',
      component: TwoYeartoFourYear
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import("../views/AboutView.vue"),
    },
    {
      path: '/GeneratedPlans',
      name: 'GeneratedPlans',
      component: GeneratedPlans
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import("../views/AboutView.vue"),
    },
    {
      path: '/plan-details/:planName', // Dynamic segment for the plan name
      name: 'PlanDetails',
      component: PlanDetails,
    },
  ],
});

export default router;
