<template>
  <div class="container-fluid transfer two-to-four-year-transfer-container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2>Two year to four year transfer plan</h2>
      </div>
    </div>
    <div class="school-names">
      <span class="school-name">
        <i class="fa-solid fa-school"></i>
        <span>{{ plan.startingSchool.name }}</span>
      </span>
      <i class="fa-solid fa-arrow-right"></i>
      <span class="school-name">
        <i class="fa-solid fa-school"></i>
        <span>{{ plan.destinationSchool.name }}</span>
      </span>
    </div>
    <div class="row two-to-four-year-report-container">
      <div class="col-md-12 pl-0">
        <button
          data-toggle="modal"
          data-target="#reportSummarModal"
          class="btn btn-primary mb-2"
          type="button"
        >
          Analyze transfer plan
        </button>
      </div>
      <ReportTable
        :school="plan.startingSchool"
        :program="plan.startingProgram"
        :destProgramName="plan.destinationProgram.name"
        :planTerm="plan.transferReport.sourceDegreeplan"
        :isStartingSchool="true"
      />
      <ReportTable
        :school="plan.destinationSchool"
        :program="plan.destinationProgram"
        :destProgramName="plan.destinationProgram.name"
        :planTerm="plan.transferReport.destDegreeplan"
        :isStartingSchool="false"
      />
    </div>
    <ReportSummaryModal
      :report="plan.transferPlanAnalysis"
    ></ReportSummaryModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import ReportTable from "@/components/ReportTable.vue";
import ReportSummaryModal from "@/components/ReportSummaryModal.vue";
import store from "@/store";

export default defineComponent({
  name: "PlanDetails",
  setup() {
    const route = useRoute();
    const planName = route.params.planName;
    const plan = computed(() => {
      const filteredPlans = store.state.degreePlans.filter(
        (p) => p.name === planName
      );
      return filteredPlans.length > 0
        ? filteredPlans[0]
        : {
            name: "",
            startingSchool: { name: "" },
            destinationSchool: { name: "" },
            startingProgram: { name: "" },
            destinationProgram: { name: "" },
            transferPlanAnalysis: {},
            transferReport: {
              sourceDegreeplan: {},
              destDegreeplan: {},
            },
          };
    });

    return { plan };
  },
  components: {
    ReportTable,
    ReportSummaryModal,
  },
});
</script>
