<template>
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
    integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
    crossorigin="anonymous"
  />
  <div class="container-fluid main-container p-5">
    <div class="row">
      <div class="col-md-12 table-container">
        <PlanListTable
          title="Two year to Four year Plans"
          :generatedPlans="twoToFourYearGeneratedPlans"
          :isLoadingPlans="isLoadingPlans"
        ></PlanListTable>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12 table-container">
        <PlanListTable
          title="Transfer Evaluations"
          :generatedPlans="transferEvaluations"
        ></PlanListTable>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import PlanListTable from "../components/PlanListTable.vue";
import store from "@/store";

export default defineComponent({
  name: "GeneratedPlans",
  props: {},
  setup() {
    const twoToFourYearGeneratedPlans = computed(() => store.state.degreePlans);
    const isLoadingPlans = computed(() => store.state.isLoadingPlans);

    return { twoToFourYearGeneratedPlans, isLoadingPlans};
  },

  data() {
    return {
      transferEvaluations: [] as Array<any>,
    };
  },
  methods: {},
  components: { PlanListTable },
});
</script>
<style>
.table-container {
  background-color: #edf1f4;
  border-radius: 5px;
}
.main-container {
  font-size: 11px;
}
</style>
