<template>
  <table>
    <thead>
      <tr>
        <th class="thead-arizonablue" scope="col" colspan="4" v-if="school">
          <span class="mr-2">
            completed coursework through transfer - {{ school.name }}</span
          >
        </th>
        <th class="thead-arizonablue" scope="col" colspan="4" v-else>
          School Name
        </th>
      </tr>
    </thead>
    <tbody class="table-white">
      <tr>
        <th>Course name</th>
        <th>Credit</th>
        <th>Equivalency</th>
        <!-- <th>Satisfied Requirement</th> -->
      </tr>
      <tr v-for="course in courseList">
        <td>
          {{ course.code }}
          {{ course.name }}
        </td>
        <td>
          {{ course.minimum_credits }}
        </td>
        <td>
          {{ course.equivalency ? course.equivalency : "" }}
        </td>
        <!-- <td>
          {{ course.dest_req.length > 0 ? course.dest_req : "" }}
        </td> -->
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { program } from "@babel/types";
import { defineComponent } from "vue";
import TermTable from "./TermTable.vue";

export default defineComponent({
  name: "ReportTable",

  props: {
    school: {
      type: Object,
      required: true,
    },
    destProgramName: {
      type: String,
      required: false,
    },
    courseList: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},
  components: { TermTable },
});
</script>
