<template>
  <div class="row">
    <div class="col-md-8">
      <h3>{{ title }}</h3>
    </div>
    <div class="col-md-4 mt-3">
      <!-- <search-select
        class="school-selection-search"
        :items="generatedPlans"
        :labelKey="'name'"
        placeholder="Search a plan"
      /> -->
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th class="thead-arizonablue">Generated plan</th>
        <th class="thead-arizonablue">Community College</th>
        <th class="thead-arizonablue">Destination School</th>
        <th class="thead-arizonablue">Community College Program</th>
        <th class="thead-arizonablue">Destination Program</th>
      </tr>
    </thead>
    <tbody v-if="isLoadingPlans">
      <tr >
        <th scope="row" colspan="5">
          <div class="loader"></div>
        </th>
      </tr>
    </tbody>
    <tbody v-else class="table-white">
      <tr v-if="generatedPlans.length == 0">
        <th scope="row" colspan="5">No plans found!</th>
      </tr>

      <tr v-else v-for="plan in generatedPlans">
        <td>
          <router-link :to="`/plan-details/${plan.name}`">
            {{ plan.name }}
          </router-link>
        </td>
        <td>
          {{ plan.startingSchool.name }}
        </td>
        <td>
          {{ plan.destinationSchool.name }}
        </td>
        <td>
          {{ plan.startingProgram.name }}
        </td>
        <td>
          {{ plan.destinationProgram.name }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SearchSelect from "@/components/SearchSelect.vue";

export default defineComponent({
  name: "PlanListTable",
  props: {
    generatedPlans: {
      type: Array as () => ReadonlyArray<any>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isLoadingPlans:{
      type: Boolean,
      required: false
    }
  },
  methods: {},
  components: { SearchSelect },
});
</script>
<style>
.table-container {
  background-color: #edf1f4;
  border-radius: 5px;
}
</style>
