<template>
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
    integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
    crossorigin="anonymous"
  />
  <div class="container-fluid transfer two-to-four-year-transfer-container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2>Two year to four year transfer plan</h2>
      </div>
    </div>
    <div class="row two-to-four-year-form-container">
      <div class="row">
        <div class="col-md-6">
          <h5>Select your starting school</h5>
          <search-select
            class="school-selection-search"
            :items="startingSchools"
            :labelKey="'name'"
            :placeholder="
              startingSchool ? startingSchool.name : 'Search Schools'
            "
            @item-selected="selectStartingSchool"
          />
        </div>
        <div class="col-md-6">
          <h5>Select program at your starting school</h5>
          <search-select
            class="school-selection-search"
            :isLoading="isLoadingStartingSchoolPrograms"
            :items="startingSchoolPrograms"
            :labelKey="'name'"
            :placeholder="
              startingProgram ? startingProgram.name : 'Search Programs'
            "
            @item-selected="selectStartingProgram"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h5>Select your destination school</h5>
          <search-select
            class="school-selection-search"
            :items="destinationSchools"
            :labelKey="'name'"
            :placeholder="
              destinationSchool ? destinationSchool.name : 'Search Schools'
            "
            @item-selected="selectDestinationSchool"
          />
        </div>
        <div class="col-md-6">
          <h5>Select program at your destination school</h5>
          <search-select
            class="school-selection-search"
            :isLoading="isLoadingDestinationSchoolPrograms"
            :items="destinationSchoolPrograms"
            :labelKey="'name'"
            :placeholder="
              destinationProgram ? destinationProgram.name : 'Search Programs'
            "
            @item-selected="selectDestProgram"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center mt-5">
          <button
            class="btn btn-primary"
            type="button"
            @click="generateReport"
            :disabled="
              !startingProgram || !destinationProgram || isButtonClicked
            "
          >
            Generate Transfer Plan
          </button>
        </div>
      </div>
    </div>
    <div v-if="transferReportLoaded" class="school-names">
      <span class="school-name" :class="{ set: startingSchool !== null }">
        <i class="fa-solid fa-school"></i>
        <span v-if="startingSchool">{{ startingSchool.name }}</span>
        <span v-else>Starting School</span>
      </span>
      <i class="fa-solid fa-arrow-right"></i>
      <span class="school-name" :class="{ set: destinationSchool !== null }">
        <i class="fa-solid fa-school"></i>
        <span v-if="destinationSchool">{{ destinationSchool.name }}</span>
        <span v-else>Destination School</span>
      </span>
    </div>
    <div
      v-if="transferReportLoaded"
      class="row two-to-four-year-report-container"
    >
      <div class="col-md-12 pl-0">
        <button
          v-if="userIsLoggedIn"
          @click="saveTransferPlan"
          class="btn btn-success mb-2 mr-2 ml-0"
          :disabled="planSaved || isSavingPlan"
        >
          <span class="button-caption" v-if="isSavingPlan">
            Saving Plan ...
          </span>
          <span class="button-caption" v-else-if="planSaved">Plan Saved</span>
          <span class="button-caption" v-else> Save to my Plans</span>
        </button>
        <button
          data-toggle="modal"
          data-target="#reportSummarModal"
          class="btn btn-primary mb-2"
          type="button"
        >
          Analyze transfer plan
        </button>
      </div>

      <ReportTable
        :school="startingSchool"
        :program="startingProgram"
        :destProgramName="destinationProgram.name"
        :planTerm="transferReport.sourceDegreeplan"
        :isStartingSchool="true"
      />
      <ReportTable
        :school="destinationSchool"
        :program="destinationProgram"
        :destProgramName="destinationProgram.name"
        :planTerm="transferReport.destDegreeplan"
        :isStartingSchool="false"
      />
    </div>
    <ReportSummaryModal :report="transferPlanAnalysis"></ReportSummaryModal>
    <div class="no-results text-center" v-if="loadingTransferReport">
      <h5>{{ loadingMessage }}</h5>
      <div class="loader"></div>
    </div>
    <div class="text-center" v-if="error">
      <h4>Unable to create a feasible transfer plan.</h4>
      <h6 v-if="error">{{ errorMsg }}</h6>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import type School from "@/models/school";
import { defineComponent, computed } from "vue";
import SearchSelect from "@/components/SearchSelect.vue";
import SearchSelectCredits from "@/components/SearchSelectCredits.vue";
import ReportTable from "@/components/ReportTable.vue";
import ReportSummaryModal from "@/components/ReportSummaryModal.vue";
import type Course from "@/models/course";
import type Program from "@/models/program";
import httpClient from "@/common/httpClient";
import store from "@/store";
// import AWS from "aws-sdk";

export default defineComponent({
  name: "TransferView",

  data() {
    return {
      /** List of destination schools for selection */
      destinationSchools: [] as Array<School>,

      /** The selected destination school */
      destinationSchool: null as unknown as School,

      /** List of programs at destination school */
      destinationSchoolPrograms: [] as Array<Program>,

      /** Program selected at destination school */
      destinationProgram: null as unknown as Program,

      /** Courses offered by the destination school */
      destinationSchoolCourses: [] as Array<Course>,

      /** List of starting schools for selection */
      startingSchools: [] as Array<School>,

      /** The selected starting school */
      startingSchool: null as unknown as School,

      /** List of programs at starting school */
      startingSchoolPrograms: [] as Array<Program>,

      /** Program selected at destination school */
      startingProgram: null as unknown as Program,

      /** Courses offered by the starting school */
      startingSchoolCourses: [] as Array<Course>,

      isLoadingStartingSchoolPrograms: false,
      isLoadingDestinationSchoolPrograms: false,
      isLoadingSourceCourses: false,
      isLoadingDestCourses: false,
      isButtonClicked: false,
      loadingTransferReport: false,
      transferReportLoaded: false,
      error: false,
      errorMsg: "",
      loadingMessage: "Generating Transfer Plan",
      transferPlanAnalysis: " " as Object,
      transferReport: {
        sourceDegreeplan: {},
        destDegreeplan: {},
      },
      plan: null as any,
      planSaved: false,
      isSavingPlan: false,
    };
  },

  mounted() {
    // Get schools
    this.getSchools();
  },
  setup() {
    // Computed property to determine if the user is logged in
    const userIsLoggedIn = computed(() =>
      store.state.userToken != null ? true : false
    );

    return {
      userIsLoggedIn,
    };
  },

  methods: {
    async generateReport() {
      this.isButtonClicked = true;
      this.error = false;
      this.errorMsg = "";
      this.planSaved = false;
      this.loadingTransferReport = true;

      // Wait for the courses to load if they are not already loaded
      if (this.isLoadingSourceCourses || this.isLoadingDestCourses) {
        while (this.isLoadingSourceCourses || this.isLoadingDestCourses) {
          await new Promise((resolve) => setTimeout(resolve, 200)); // Poll every 500ms
        }
      }

      try {
        this.loadingMessage = "Generating Transfer Plan...";
        // this.getTransferPlan();
        this.generateTwoToFourPlanLocalhost();
        this;
      } catch (error) {
        console.log(error);
        this.error = true;
        this.loadingTransferReport = false;
        this.isButtonClicked = false;
      }
    },

    async getTransferPlan() {
      const data = {
        source_program: this.startingProgram,
        dest_program: this.destinationProgram,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "2D9QpLXVXo2fY6E9B1dTb8HddPygm7Ga84NsQ1rX",
        },
      };

      await axios
        .post(
          "https://8exykg10n2.execute-api.us-west-2.amazonaws.com/transfer-plan-new",
          data,
          config
        )
        .then((response) => {
          console.log("Got two to four year plan: ");
          const responseObj = JSON.parse(response.data);

          let source_degreeplan = responseObj.source_degreeplan;
          let dest_degreeplan = responseObj.destination_degreeplan;
          this.transferPlanAnalysis = responseObj.transfer_plan_report;

          if (responseObj.transfer_map) {
            source_degreeplan = this.addCourseEquivalency(
              responseObj.source_degreeplan,
              responseObj.transfer_map
            );
            source_degreeplan = this.addSatisfiedRequirements(
              source_degreeplan,
              responseObj.source_courses_with_requirement_names,
              responseObj.dest_courses_with_requirement_names,
              true
            );

            dest_degreeplan = this.addSatisfiedRequirements(
              responseObj.destination_degreeplan,
              responseObj.dest_courses_with_requirement_names,
              [],
              false
            );
          }
          this.transferReport.sourceDegreeplan = this.createTermObjects(
            source_degreeplan,
            0
          );

          this.transferReport.destDegreeplan = this.createTermObjects(
            dest_degreeplan,
            4
          );

          this.plan = {
            name:
              this.startingProgram.name + " - " + this.destinationProgram.name,
            startingSchool: this.startingSchool,
            destinationSchool: this.destinationSchool,
            startingProgram: this.startingProgram,
            destinationProgram: this.destinationProgram,
            transferReport: this.transferReport,
            transferPlanAnalysis: this.transferPlanAnalysis,
          };

          this.transferReportLoaded = true;
          this.isButtonClicked = false;
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
        });
      this.loadingTransferReport = false;
      this.isButtonClicked = false;
    },

    async saveTransferPlan() {
      this.isSavingPlan = true;
      await store.methods.uploadUserFile(
        this.plan,
        this.startingProgram.name,
        this.destinationProgram.name
      );
      this.isSavingPlan = false;
      this.planSaved = true;
    },

    addCourseEquivalency(
      source_degreeplan: Array<any>,
      transfer_map: Array<any>
    ) {
      // Create a lookup object from the transfer_map for faster lookups
      let transfer_lookup = {} as any;
      transfer_map.forEach((obj) => {
        for (let key in obj) {
          transfer_lookup[key] = obj[key];
        }
      });

      source_degreeplan.forEach((course) => {
        if (transfer_lookup[course.id.toString()]) {
          const equivalency_array = transfer_lookup[course.id.toString()];
          let equivalencies: string[] = [];

          equivalency_array.forEach((course_id: string) => {
            const foundCourse = this.destinationSchoolCourses.find(
              (destCourse) => destCourse.id === parseInt(course_id)
            );
            if (foundCourse) {
              equivalencies.push(`${foundCourse.code}`);
            }
          });

          course.equivalency = equivalencies.join(", ");
        }
      });
      return source_degreeplan;
    },

    addSatisfiedRequirements(
      degreeplan: Array<any>,
      courses_with_req_names: Array<any>,
      dest_courses_with_requirement_names: Array<any>,
      isStartingplan: Boolean
    ) {
      degreeplan.forEach((course) => {
        if (isStartingplan) {
          course.start_req = courses_with_req_names[course.code];
          let courseCodes = course.equivalency.split(", "); // Splitting the equivalency property
          let destReqs = [];
          for (let code of courseCodes) {
            let destReq = dest_courses_with_requirement_names[code]; // Finding each course code in the dest_courses_with_requirement_names
            if (destReq) {
              destReqs.push(destReq[0]);
            }
          }
          course.dest_req = destReqs;
        } else {
          course.dest_req = courses_with_req_names[course.code];
        }
      });

      return degreeplan;
    },

    createTermObjects(courseList: Array<any>, startTermFrom: number) {
      // Determine the highest term number
      let maxTerm = Math.max.apply(
        Math,
        courseList.map((course) => course.term)
      );

      let minTerm = Math.min.apply(
        Math,
        courseList.map((course) => course.term)
      );

      // Create the term array
      let terms = [] as Array<any>;
      for (let i = minTerm; i <= maxTerm; i++) {
        let term = {
          id: i,
          name: "Term " + (i + startTermFrom),
          credits: 15,
          position: 0,
          plan_items: [],
        };
        terms.push(term);
      }

      // Assign courses to their respective term
      terms.forEach((term) => {
        courseList.forEach((course) => {
          if (course.term == term.id) {
            term.plan_items.push(course);
          }
        });
      });

      return terms;
    },

    /**
     * Fetch all programs for the given school
     */
    async getPrograms(school: School): Promise<Program[]> {
      const programs = [] as Array<Program>;

      // 108 is the code for the 2021 academic period and 112 is the code for the 2022 academic period
      var academic_period = 112;

      await axios
        .get(
          `${httpClient.defaults.baseURL}/universities/${school.id}/academic_periods/${academic_period}/university_layouts`
        )
        .then((response) => {
          const filteredPrograms = response.data.filter((program: any) => {
            // Filter entities that are not a program and that don't have periodDegreeJoin
            if (
              program.type === "program" &&
              program.period_degree_joins.length > 0
            ) {
              // Filter out peridDegreeJoin that are not a MAJOR
              program.period_degree_joins.filter((degreeJoin: any) => {
                if (degreeJoin.code === "MAJOR") {
                  return true;
                } else {
                  return false;
                }
              });
              return true;
            } else {
              return false;
            }
          });
          filteredPrograms.forEach((element: any) => {
            const program = {
              name: element.program.name,
              id: element.program.id,
              code: element.program.code,
              // Code should equal "MAJOR"
              periodDegreeJoins: element.period_degree_joins,
              plan_period_join: null as unknown as number,
              plan_terms: [],
              requirements: [],
              // disable: !element.program.name.includes("Engineering"),
              disable: true,
            };

            if (
              program.code == "AOSASI" ||
              program.code == "AOAALA" ||
              program.code == "ECEBSECE" ||
              program.code == "BMEBSBME" ||
              program.code == "INEBSINE" ||
              program.code == "PSYCBA" ||
              program.code == "PSYSBS"
            ) {
              program.disable = false; // Set disable to false if it matches one of the specified program names
            }
            programs.push(program);
          });
        });
      return programs;
    },
    /**
     * Fetch programs requirement for the given program
     */
    async getProgramRequirement(program: Program, isDestProgram: Boolean) {
      // Define a unique key for storing and retrieving the cached data
      const cacheKey = `requirements_${isDestProgram ? "dest" : "source"}_${
        program.id
      }`;

      // Attempt to retrieve the cached data
      const cachedData = localStorage.getItem(cacheKey);

      // If cached data is found, parse and use it
      if (cachedData) {
        console.log("Using cached data for", program.name);
        const requirements = JSON.parse(cachedData);

        if (isDestProgram) {
          this.destinationProgram.requirements = requirements;
        } else {
          this.startingProgram.requirements = requirements;
        }
        // Return early since we've loaded the data from cache
        return;
      }

      // If no cached data is found, proceed to fetch it from the server
      this.loadingMessage = `Fetching requirements for ${program.name}`;
      try {
        const response = await axios.get(
          `${httpClient.defaults.baseURL}/universities/${
            isDestProgram
              ? this.destinationSchool.slug
              : this.startingSchool.slug
          }/period_degree_joins/${program.periodDegreeJoins[0].id}/requirements`
        );

        // Update the respective program's requirements with the fetched data
        const requirements = response.data;
        if (isDestProgram) {
          this.destinationProgram.requirements = requirements;
        } else {
          this.startingProgram.requirements = requirements;
        }

        // Cache the newly fetched data for future use
        localStorage.setItem(cacheKey, JSON.stringify(requirements));
        console.log(`Fetched and cached requirements for ${program.name}`);
      } catch (error) {
        console.error(
          `Failed to fetch requirements for ${program.name}:`,
          error
        );
      }
    },
    /**
     * Fetch all schools
     */
    getSchools() {
      this.destinationSchools = [
        {
          id: 4036,
          name: "University of Arizona",
          slug: "university-of-arizona",
          disable: false,
        },
      ];
      this.startingSchools = [
        {
          id: 3056,
          name: "Pima Community College",
          slug: "pima-community-college",
          disable: false,
        },
        {
          id: 4657,
          name: "Maricopa Community College",
          slug: "maricopa-community-college",
          disable: true,
        },
        {
          id: 4658,
          name: "Eastern Arizona College",
          slug: "eastern-arizona-college",
          disable: true,
        },
      ];
    },

    /**
     * Fetch all courses for the given school
     */
    async getCourses(school: School): Promise<Course[]> {
      this.loadingMessage = `Fetching Courses for the ${school.name}...`;
      const courses = [] as Array<Course>;
      await axios
        .get(
          `https://az-transfer-portal.s3.us-west-2.amazonaws.com/json/coursesPrereq/${school.slug}.json`
        )
        .then((response) => {
          const filteredCourses = response.data.filter((course: any) => {
            // Check if the academic_period year is equal to 2021
            return (
              course.academic_period && course.academic_period.year === 2021
            );
          });
          courses.push(...filteredCourses);
        })
        .catch((err) => console.log(err));

      return courses;
    },

    /**
     * Handles the selection of the starting school
     */
    async selectStartingSchool(school: School) {
      this.startingSchool = school;
      this.transferReportLoaded = false;
      this.isLoadingStartingSchoolPrograms = true;
      this.isLoadingSourceCourses = true;
      this.startingSchoolPrograms = await this.getPrograms(this.startingSchool);
      this.startingSchoolCourses = await this.getCourses(school);
      this.isLoadingStartingSchoolPrograms = false;
      this.isLoadingSourceCourses = false;
    },

    /**
     * Handles the selection of the destination school
     */
    async selectDestinationSchool(school: School) {
      this.destinationSchool = school;
      this.transferReportLoaded = false;
      this.isLoadingDestinationSchoolPrograms = true;
      this.isLoadingDestCourses = true;
      this.destinationSchoolPrograms = await this.getPrograms(
        this.destinationSchool
      );
      this.destinationSchoolCourses = await this.getCourses(school);
      this.isLoadingDestinationSchoolPrograms = false;
      this.isLoadingDestCourses = false;
    },

    /**
     * Handles the selection of the destination Program
     */
    async selectDestProgram(program: Program) {
      this.transferReportLoaded = false;
      this.destinationProgram = program;
      console.log(program.name);
    },

    /**
     * Handles the selection of the starting Program
     */
    async selectStartingProgram(program: Program) {
      this.transferReportLoaded = false;
      this.startingProgram = program;
      console.log(program.name);
    },

    // async savePlanToDynamoDB(plan: string) {
    // AWS.config.update({
    // region: "",
    // // For development purposes only
    // accessKeyId: "",
    // secretAccessKey: "",
    // });
    // // DynamoDB Document Client
    // const docClient = new AWS.DynamoDB.DocumentClient();
    // const params = {
    //   TableName: "TransferPlans",
    //   Item: {
    //     startingProgramCode: this.startingProgram.name,
    //     destinationProgramCode: this.destinationProgram.name,
    //     DP: plan,
    //   },
    // };
    // // Save to DynamoDB
    // try {
    //   docClient.put(params).promise();
    //   console.log("Data saved to DynamoDB successfully!");
    // } catch (dbError) {
    //   console.error("Failed to save data to DynamoDB:", dbError);
    //   this.error = true;
    // }
    // },

    downloadPlan(plan: string) {
      const fileName = `${this.destinationProgram.name} - ${this.startingProgram.name}.json`;
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(plan)
      );
      element.setAttribute("download", fileName);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    async generateTwoToFourPlanLocalhost() {
      const data = {
        source_school: this.startingSchool,
        dest_school: this.destinationSchool,
        source_program: this.startingProgram,
        dest_program: this.destinationProgram,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "2D9QpLXVXo2fY6E9B1dTb8HddPygm7Ga84NsQ1rX",
        },
      };

      await axios
        .post("http://localhost:8080/transfer_plan", data, config)
        .then((response) => {
          console.log("Got two to four year plan: ");
          const responseObj = response.data;
          console.log(responseObj);

          this.transferReport.sourceDegreeplan = this.createTermObjects(
            responseObj.source_degreeplan,
            0
          );

          this.transferReport.destDegreeplan = this.createTermObjects(
            responseObj.destination_degreeplan,
            4
          );

          this.transferPlanAnalysis = responseObj.transfer_plan_report;
          this.transferReportLoaded = true;
          this.isButtonClicked = false;

          const jsonStr = JSON.stringify(responseObj);
          this.downloadPlan(jsonStr);
          // this.savePlanToDynamoDB(jsonStr);
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
        });
      this.loadingTransferReport = false;
      this.isButtonClicked = false;
    },
  },
  components: {
    SearchSelect,
    SearchSelectCredits,
    ReportTable,
    ReportSummaryModal,
  },
});
</script>
<style>
#CreditError {
  font-size: 14px;
  color: red;
}
.save-transfer-plan-container {
  display: flex;
  justify-content: flex-end; /* Aligns content to the right */
}

.save-transfer-plan {
  /* Optional styling for the link */
  cursor: pointer;
  color: #007bff; /* Bootstrap's default link color */
  text-decoration: underline;
}

.plan-saved {
  color: green;
}
</style>
