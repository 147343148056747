<template>
  <header id="region_header">
    <router-link class="logo" to="/"
      ><img src="./assets/UIC-logo.png"
    /></router-link>
    <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/transfer">Transfer to UIC</router-link>
      <a v-if="!loginSuceess" data-toggle="modal" data-target="#LoginModal"
        >Login</a
      >
      <router-link v-if="loginSuceess" to="/GeneratedPlans"
        >Generated Plans</router-link
      >
      <router-link v-if="loginSuceess" to="/" @click="handleLogout"
        >Logout</router-link
      >
    </nav>
  </header>
  <div class="under-development-notice">
    <strong>Under Development:</strong> This website is currently being updated
    and improved. Some features may not be fully functional.
  </div>
  <div class="main-content">
    <router-view />
    <LoginModal
      modalID="LoginModal"
      :setLoginSucess="setLoginSucess"
      ref="loginModal"
    ></LoginModal>
  </div>
  <footer id="footer_site">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="text-center-sm text-center-xs text-left" id="footer_logo">
            <img src="./assets/UIC-logo.png" />
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="text-center-sm text-center-xs text-right"
            id="footer_menu"
          >
            <ul>
              <li><router-link to="/">Home</router-link></li>
              <li>
                <a href="/">About us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <hr />
        <div class="col-md-12">
          <div class="text-center" id="footer_copyright">
            University of Illinois Chicago | 1200 W Harrison St, Chicago, IL
            60607 <br />
            Phone: (312) 996-7000 <br />
            © 2024 The Board of Trustees of the University of Illinois
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoginModal from "@/components/LoginModal.vue";
import store from "@/store";

export default defineComponent({
  name: "App",
  components: {
    LoginModal,
  },
  data() {
    return {
      loginSuceess: localStorage.getItem("userToken") !== null,
    };
  },
  methods: {
    setLoginSucess() {
      this.loginSuceess = true;
    },
    handleLogout() {
      this.loginSuceess = false;
      store.methods.logout();
    },
  },
  created() {
    store.methods.initUserSession();
  },
});
</script>
<style>
.under-development-notice {
  background-color: #fff3cd;
  color: #856404;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ffeeba;
}
</style>
