<template>
  <div class="ube-autocomplete-search">
    <!-- Search Bar -->
    <div class="search-bar" :class="{ active: showingItems }">
      <span class="icon">
        <i class="fa-solid fa-magnifying-glass"></i>
      </span>
      <input
        type="text"
        v-model="searchString"
        :placeholder="placeholder"
        @focus="showList"
        @focusout="hideList"
      />
      <span class="clear-icon"> </span>
    </div>

    <!-- List of Items -->
    <div class="item-list-container" v-if="showingItems">
      <div
        v-for="item of filteredItems"
        :key="item['code']"
        @click="selectItem(item)"
        :class="item['disable'] ? 'list-item disabledOption' : 'list-item'"
      >
        {{ item[labelKey] }}
        <!-- <span v-if="item['code']">({{ item['code'] }})</span> -->
      </div>
      <div class="no-results" v-if="isLoading">
        <div class="loader"></div>
      </div>
      <div class="no-results" v-else-if="filteredItems.length === 0">
        No Results
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

export default defineComponent({
  name: "UbeAutoComplete",

  props: {
    /** List of items */
    items: {
      type: Array as PropType<Array<any>>,
      required: true,
    },

    /** Placeholder text */
    placeholder: {
      type: String,
      required: false,
    },

    /** The property to use as a label */
    labelKey: {
      type: String,
      required: true,
    },

    isLoading: {
      type: Boolean,
      required: false,
    },
  },

  emits: ["item-selected"],

  data() {
    return {
      /** String to search items on */
      searchString: "",

      /** Flag to show items */
      showingItems: false,
    };
  },

  computed: {
    /** List of items filtered by the search string and without duplicates */
    filteredItems(): Array<any> {
      let filtered =
        this.searchString === ""
          ? this.items
          : this.items.filter((i) =>
              (i[this.labelKey] as string)
                .toLowerCase()
                .includes(this.searchString.toLowerCase())
            );

      const seen = new Set();
      const deduplicated = filtered.filter((item) => {
        const key = item["code"] ? item["code"] : item["id"];
        if (!seen.has(key)) {
          seen.add(key);
          return true;
        }
        return false;
      });

      //Sort the programs so that the programs which are not grayed out show up at the top in alphabetical order
      deduplicated.sort(function (a, b) {
        // first sort by disable property
        if (a.disable !== b.disable) {
          return a.disable ? 1 : -1;
        }

        // then sort by name
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      });

      return deduplicated;
    },
  },

  methods: {
    /**
     * Clears the search string
     */
    clearSearchString() {
      this.searchString = "";
    },

    close() {
      this.showingItems = false;
      this.clearSearchString();
    },

    showList() {
      this.showingItems = true;
    },

    hideList() {
      setTimeout(() => (this.showingItems = false), 200);
    },

    /**
     * Emit an event with the given item
     */
    selectItem(item: any) {
      this.close();
      if (!item["disable"]) {
        this.$emit("item-selected", item);
      }
    },
  },
});
</script>
<style>
.disabledOption {
  color: darkgray;
  cursor: default !important;
}
</style>
