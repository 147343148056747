<template>
  <div class="ube-autocomplete-search">
    <!-- Search Bar -->
    <div class="search-bar">
      <span class="icon">
        <i class="fa-solid fa-magnifying-glass"></i>
      </span>
      <input type="number" :min="min" :max="max" v-model="value" />
      <span class="clear-icon"> </span>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "NumericInput",
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      default: null,
    },
  },
};
</script>
<style>
.disabledOption {
  color: darkgray;
  cursor: default !important;
}
</style>
