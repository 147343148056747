import { reactive, readonly } from 'vue';
import axios from 'axios';
import {
  CognitoUserPool,
  CognitoUser,
} from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
  UserPoolId: "us-west-2_xlvB8IRYA",
  ClientId: "399kkbive36jlq6137rn8p0moc",
});

interface State {
  cognitoUser: any;
  userToken: string | null;
  degreePlans: any[];
  isLoadingPlans: boolean;
}

interface Methods {
  setCognitoUser(user: any): void;
  setUserToken(token: string | null): void;
  logout(): void;
  fetchUserFiles(): Promise<void>;
  uploadUserFile(degreePlan: any, destProgramName: string, StartProgramName: string): Promise<void>;
  initUserSession(): void
}

const state: State = reactive({
  cognitoUser: null,
  userToken: null,
  degreePlans: [],
  isLoadingPlans: false,
});

const methods: Methods = {
  setCognitoUser(user: any) {
    state.cognitoUser = user;
    console.log(state.cognitoUser.getUsername())
  },
  setUserToken(token: string | null) {
    state.userToken = token;
  },
  logout() {
    state.cognitoUser = null;
    state.userToken = null;
    localStorage.removeItem("userToken");
    localStorage.removeItem("cognitoUser");
  },
  async fetchUserFiles() {
    if (!state.cognitoUser || !state.userToken) {
      console.error("Cannot fetch files without a user and token.");
      return;
    }
    try {
      state.isLoadingPlans = true
      const response = await axios.get("https://8exykg10n2.execute-api.us-west-2.amazonaws.com/TwotoFour/files", {
        headers: { Authorization: `Bearer ${state.userToken}` },
        params: { userId: state.cognitoUser.getUsername() },
      });
      state.degreePlans = response.data;
      console.log(state.degreePlans)
      state.isLoadingPlans = false
    } catch (error) {
      console.error("Error fetching user files:", error);
      state.isLoadingPlans = false;
    }
  },
  async uploadUserFile(degreePlan: any, destProgramName: string, StartProgramName: string) {
    if (!state.cognitoUser || !state.userToken) {
      console.error("Cannot upload file without a user and token.");
      return;
    }
    try {
      const response = await axios.post(
        "https://8exykg10n2.execute-api.us-west-2.amazonaws.com/TwotoFour/files",
        { degreePlan },
        {
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${state.userToken}` },
          params: { "userId": state.cognitoUser.getUsername(), "startProgram": StartProgramName, "destProgram": destProgramName },
        }
      );
      console.log("Upload successful", response.data);
      await methods.fetchUserFiles();
    } catch (error) {
      console.error("Error uploading user file:", error);
    }
  },
  initUserSession() {
    const token = localStorage.getItem('userToken');
    const username = localStorage.getItem('username');

    if (token && username) {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });
      this.setUserToken(token);
      this.setCognitoUser(cognitoUser);
      this.fetchUserFiles();
    }
  },
  
};

export default {
  state: readonly(state),
  methods,
};
