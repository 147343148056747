import axios from 'axios'

export interface MockAxiosResponse<T> {
  data: T
}

/**
 * The Axios Client to be configured
 */
const httpClient = axios.create({
  baseURL: 'https://bb-api.arizonadegreeplans.com',
  headers: { 'Content-Type': 'application/json' }
})

export default httpClient